<template>
  <div class="video-container iq-main-slider">

     <vue-plyr :options="options">
          <video
            controls
            data-poster="logo.png"
            :src="video.content_url"
          >
            <source
              size="720"
              :src="video.content_url"
              type="video/mp4"

            />
          </video>
        </vue-plyr>

  </div>
</template>

<style>
.video-container {
  height: 100% !important;
}
</style>
<script>

export default {
  name: 'BannerVideo',
  props: ['video'],

  data () {
    return {
      options: { quality: { default: '1080p' } },
      videoUrl: ''
    }
  },
  mounted () {
    var videoObj = localStorage.getItem('videoUrl')
    const url = JSON.parse(videoObj)
    this.videoUrl = url.content_url
  }
}
</script>
