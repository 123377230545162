<template>
<div>
  <BannerVideo :video="video"></BannerVideo>
  <Details :video="video"/>
  <div class="mt-4"></div>
  <Items v-for="(category,key) in categories" :category="category" :categoryID="key" :key="key" />
</div>
</template>
<script>
import { core } from '../../config/pluginInit'
import Details from './MovieDetailPage/Detail'
import BannerVideo from './MovieDetailPage/BannerVideo'
import Items from './MovieDetailPage/ListItems'
import ApiService from '../../services/api'

export default {
  name: 'MovieDetail',
  components: {
    Details,
    Items,
    BannerVideo
  },
  data () {
    return {
      video: {},
      categories: []
    }
  },
  methods: {
    async openContent () {
      ApiService.openContent(this.$route.params.contentID).then((response) => {
        this.$store.commit('setPoints', response.data.user_points)
        this.video = response.data.data
        localStorage.setItem('videoUrl', JSON.stringify(response.data.data))
      })
    },
    async getContentCategories () {
      ApiService.getLastContentCategories().then((response) => {
        this.categories = response.data.data
      })
    }
  },
  mounted () {
    core.index()
    localStorage.setItem('videoUrl', {})
    this.openContent()
    this.getContentCategories()
  }
}
</script>
